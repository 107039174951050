.table-container {
    table {
        border: 1px solid #c4bdbd;
        .table-header {
            th {
                padding: 7px 5px;
                text-align: center;
                font-weight: 600;
            }
            .MuiTableSortLabel-root {
                &:hover {
                    color: rgb(230 230 230)
                }
                &.active {
                    color: #fff;
                }
            }
        }
        .table-body {
            th {
                padding: 7px 5px;
                text-align: left;
                text-transform: capitalize;
            }
            td {
                padding: 7px 5px;
                text-align: center;
                text-transform: capitalize;
            }
            .inner-row {
                .inner-cell {
                    .inner-table {
                        border-bottom: none;
                        .inner-header th{
                            background: #3737374a;
                            color: #333;
                            text-align: center;
                            .MuiTableSortLabel-root {
                                &:hover {
                                    color: rgb(79 78 78)
                                }
                                &.active {
                                    color: #333!important
                                }
                            }
                        }
                        .inner-header td {
                            text-align: center;
                        }
                        .inner-body th {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}