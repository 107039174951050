.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .register_admin {
        padding: 25px;
        box-shadow: 0px 0px 15px 3px #e4e4e4;
        width: 25%;
        form {
            display: flex;
            flex-direction: column;
            input {
                margin-bottom: 15px;
                width: 100%;
                height: 30px;
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .container .register_admin {
        width: 100%;
        margin: 0px 20px;
        form {
            input {
                height: 30px;
                font-size: 18px;
                padding-left: 5px;
                border-radius: 5px;
                border: 1px solid black;
            }
        }
    }
}