.login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .otp_login {
        padding: 25px;
        box-shadow: 0px 0px 15px 3px #e4e4e4;
    }
}

.otp_resend_box {
    box-shadow: 0px 0px 15px 3px #e4e4e4;
    border: 1px solid black;
    .otp_box {
        display: flex;
        align-items: center;
    }
    .otp_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
    }
}

