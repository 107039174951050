.addchef_container {
    position: relative;
    // height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.addchef_form {
    width: 80%;
    margin: auto auto;
    box-shadow: 0px 0px 6px 4px #bcbcbc;
    padding: 30px 30px 20px 30px;
    // transform: translate(0%, 25%);
    display: flex;
    align-items: center;
    form {
        width: 100%;
    }
}

.field_container {
    display: block;
    padding-bottom: 20px;
}

.form_f {
    margin-bottom: 10px;
    width: 45%;
    display: flex;
    .input-span {
        display: flex;
        flex-direction: column;
        width: 50%;
        .MuiFormControl-root {
            margin: 0;
            .label-pos {
                bottom: 30px;
            }
            .mt-0 {
                margin-top: 0;
            }
        }
        .error {
            margin: 0px;
            justify-content: flex-start;
            font-size: 12px;
        }
    }
}

.form_f:nth-child(odd) {
    float: left;
}

.form_f label {
    text-align: right;
    flex-basis: 43%;
    font-size: 15px;
    font-weight: 600;
    margin-right: 5px;
}

.select_label .selectbox {
    width: 50%;
}

.select_label .selectbox .MuiSelect-select {
    padding: 5px;
}

.select_label .selectbox .MuiSelect-select .MuiChip-label {
    padding-left: 5px;
    padding-right: 5px;
}

.error ul {
    text-align: left;
}

.error ul li {
    color: #d32f2f;
    font-size: 14px;
    list-style: none;
}

.addchef_form + .error {
    position: absolute;
    top: 40%;
    right: 0px;
}

// edit chef form modal css: 

.editchef_box {
    width: 60%;
}

@media screen and (max-width: 920px) {
    .addchef_container {
        height: auto;
        .addchef_form {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            form {
                width: 100%;
                .form_f {
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    &:nth-child(odd) {
                        float: none!important;
                    }
                    label {
                        flex-basis: auto;
                    }
                    .input-span {
                        input {
                            height: 30px;
                            font-size: 18px;
                            padding-left: 5px;
                            border-radius: 5px;
                            border: 1px solid black;
                        }
                    }
                }
            }
        }
    }
}